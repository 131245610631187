<template>
  <div>
    <title>SIM INVENTORY ~ ALIAS ITEM DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Alias Item Data
        <br />
        <h4>The following is a list of the alias master data lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div v-if="addData" class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Alias Item Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Code</label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.kodebarangalias"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Alias Item Code"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name</label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namabarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Item Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Alias Item Name</label>
                  <input
                    type="text"
                    v-model="isidata.aliasbarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Alias Item Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier Code</label>
                  <select2
                    :data="suplist"
                    :value="valuesup"
                    @update="updatesup($event)"
                  ></select2>
                  <!--<select
                    class="form-control"
                    v-model="isidata.kodesupplier"
                    id="kdsup"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="listsupp in suplist"
                      :key="listsupp.kode_supplier"
                      :value="listsupp.kode_supplier"
                    >
                      {{ listsupp.nama_supplier }}
                    </option>
                  </select> -->
                </div>
                <!-- <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Supplier Code</label>
                    <input
                      type="text"
                      v-model="isidata.kodesupplier"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Supplier Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getsupplier()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier Name</label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namasupplier"
                    placeholder="Supplier Name"
                    autocomplete="off"
                    class="form-control"
                  />
                </div> -->
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>

        <div v-if="iseditfrmData" class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Alias Item Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Code</label>
                  <input
                    type="text"
                    v-model="isidata.kodebarangalias"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Alias Item Code"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idxbarangalias"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Alias Item Name</label>
                  <input
                    type="text"
                    v-model="isidata.aliasbarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Alias Item Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier Code</label>
                  <select2
                    :data="suplist"
                    :value="valuesup"
                    @update="updatesup($event)"
                  ></select2>

                  <!--<select
                    class="form-control"
                    v-model="isidata.kodesupplier"
                    id="kdsup"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="listsupp in suplist"
                      :key="listsupp.kode_supplier"
                      :value="listsupp.kode_supplier"
                    >
                      {{ listsupp.nama_supplier }}
                    </option>
                  </select> -->
                </div>
                <!-- <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Supplier Code</label>
                    <input
                      type="text"
                      v-model="isidata.kodesupplier"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Supplier Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getsupplier()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br /> 
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier Name</label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namasupplier"
                    placeholder="Supplier Name"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>-->
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <button @click="awaldepan()" class="btn btn-success">
                  <i class="fa fa-angle-double-left"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>

        <div v-if="isExist" class="col-md-6">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">List Alias Item Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  <label>Item Code</label>
                </div>

                <div class="col-xs-1">
                  <label>:</label>
                </div>
                <div class="col-xs-7">
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    name="kodebarangaliasby"
                    v-model="kodebarangaliasby"
                    id="kodebarangaliasby"
                  />
                </div>
              </div>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  <label>Item Name</label>
                </div>

                <div class="col-xs-1">
                  <label>:</label>
                </div>
                <div class="col-xs-7">
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    name="namabarangaliasby"
                    v-model="namabarangaliasby"
                    id="namabarangaliasby"
                  />
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Alias Name</th>
                      <th>Supplier Code</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classbrg, index) in list_paged"
                      :key="classbrg.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <!-- <td>{{ classbrg.idx }}</td> -->
                      <td>{{ classbrg.kode_barang_header }}</td>
                      <td>{{ classbrg.alias }}</td>
                      <td>{{ classbrg.kode_supplier }}</td>

                      <td>
                        <!--<router-link :to="'/edit-barang-alias/' + classbrg.id">
                          <button title="Edit Alias Item" class="btn btn-info">
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        &nbsp;-->
                        <button
                          @click="EditData(classbrg.id)"
                          title="Edit Item Alias"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        &nbsp;

                        <button
                          title="Delete Alias Item"
                          @click="deleteData(classbrg.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
        <div v-else class="col-md-6">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">List Alias Item Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  <label>Item Code</label>
                </div>

                <div class="col-xs-1">
                  <label>:</label>
                </div>
                <div class="col-xs-7">
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    name="kodebarangaliasby"
                    v-model="kodebarangaliasby"
                    id="kodebarangaliasby"
                  />
                </div>
              </div>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  <label>Item Name</label>
                </div>

                <div class="col-xs-1">
                  <label>:</label>
                </div>
                <div class="col-xs-7">
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    name="namabarangaliasby"
                    v-model="namabarangaliasby"
                    id="namabarangaliasby"
                  />
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Alias Name</th>
                      <th>Supplier Code</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="5" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vbrgalias",
  components: {
    VPagination
  },
  data() {
    return {
      isidata: {
        kodebarangalias: "",
        aliasbarang: "",
        // namasupplier: "",
        namabarang: "",
        // kodesupplier: "",
        idxbarangalias: ""
      },
      isExist: true,
      addData: true,
      iseditfrmData: false,
      nmsrc: true,
      loading: false,
      namesearch: "",
      valuesup: "",
      list_paged: [],
      suplist: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    // this.fetchData()
    this.loadDataBrg();
    //this.loadData();
    this.getSupplierList();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.loadData();
      } else {
        this.searchdata();
      }
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.loadData();
      } else {
        this.searchdata();
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    updatesup(value) {
      this.valuesup = value;
    },
    getSupplierList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "supplier/getalldatasupplier?length=55000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.suplist = resp.data.data;
          this.suplist.forEach((item) => {
            item.value = item.kode_supplier;
            item.label = item.nama_supplier;
          });
          this.loading = false;
          this.loadData()
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    awaldepan() {
      this.addData = true;
      this.iseditfrmData = false;
      this.isidata.aliasbarang = "";
      this.valuesup = "";
      this.isidata.idxbarangalias = "";
      this.loadData();
    },
    EditData(id) {
      this.addData = false;
      this.iseditfrmData = true;
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/getbarang_aliasbyid?id="+this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "barang_alias/getbarang_aliasbyid?id=" + id;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp)
          this.isidata.kodebarangalias = resp.data.data.kode_barang_header;
          this.isidata.aliasbarang = resp.data.data.alias;
          this.valuesup = resp.data.data.kode_supplier;
          this.isidata.idxbarangalias = resp.data.data.id;
          this.loading = false;
          this.loadData()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadDataBrg() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbyid?id="+this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "barang/getbarangbyid?id=" + this.$route.params.id;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/getalldatabarang_alias?length=1&kode_barang_header="+this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.kodebarangalias = resp.data.data.kode_barang;
          this.isidata.namabarang = resp.data.data.nama_barang;
          // this.isidata.aliasbarang = resp.data.data.alias;
          // this.isidata.kodesupplier = resp.data.data[0].kode_supplier;
          this.loading = false;
          this.loadData()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveData() {
      this.loading = true;
      var kode = this.isidata.kodebarangalias;
      var nama = this.isidata.aliasbarang;
      var kodesup = this.valuesup;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Alias Item Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Alias Item Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodesup == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_barang_header: this.isidata.kodebarangalias,
          alias: this.isidata.aliasbarang,
          kode_supplier: this.valuesup,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPISaveData = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/savebarang_alias";
        const urlAPISaveData = this.$apiurl + "barang_alias/savebarang_alias";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.addData = true;
              this.isidata.aliasbarang = "";
              this.valuesup = "";
              this.loading = false;
              this.loadData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbyid?id="+this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "barang/getbarangbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.kodebarangaliasby = resp.data.data.kode_barang;
          this.namabarangaliasby = resp.data.data.nama_barang;
          // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/getalldatabarang_alias?length="+this.pageSize+"&page="+this.page+"&kode_barang_header="+this.kodebarangaliasby
          const urlApiGETdetail =
            this.$apiurl +
            "barang_alias/getalldatabarang_alias?length=" +
            this.pageSize +
            "&page=" +
            this.page +
            "&kode_barang_header=" +
            this.kodebarangaliasby;
          axios.get(urlApiGETdetail, { headers }).then((respdetail) => {
            // console.log(respdetail)
            if (respdetail.data.data.length == 0) {
              this.isExist = false;
            } else {
              this.isExist = true;
              this.list_paged = respdetail.data.data;
            }
          });
          // this.isidata.aliasbarang = resp.data.data.alias;
          // this.isidata.kodesupplier = resp.data.data[0].kode_supplier;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/getalldatabarang_alias?length="+this.pageSize+"&page="+this.page+"&cari="+this.namesearch+"&kode_barang_header="+this.kodebarangaliasby
      const urlApiGETdetail =
        this.$apiurl +
        "barang_alias/getalldatabarang_alias?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&cari=" +
        this.namesearch +
        "&kode_barang_header=" +
        this.kodebarangaliasby;
      axios
        .get(urlApiGETdetail, {
          params,
          headers: { Authorization: tokenlogin }
        })
        .then((resp) => {
          console.log(resp);
          this.list_paged = resp.data.data;
          this.count = resp.data.datatotalcount;
          this.loading = false;
          this.nmsrc = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kode = this.isidata.kodebarangalias;
      var nama = this.isidata.aliasbarang;
      var kodesup = this.valuesup;
      var idx = this.isidata.idxbarangalias;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Alias Item Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Alias Item Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodesup == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_barang_header: this.isidata.kodebarangalias,
          alias: this.isidata.aliasbarang,
          kode_supplier: this.valuesup,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIEdit = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/updatebarang_alias/"+ idx;
        const urlAPIEdit =
          this.$apiurl + "barang_alias/updatebarang_alias/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIEdit, paramdata, { headers: headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.loadData();
              this.awaldepan();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.loadData();
              this.awaldepan();
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            this.loading = false;
          });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/hapusbarang_alias/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "barang_alias/hapusbarang_alias/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
